<template>
    <div class="door_open_box_record_content">
        <div class="door_open_box_record_tabler">

            <div style="margin-left: 20px;">
                <el-date-picker v-model="datePickerValue" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                                @change="datePickerChange">
                </el-date-picker>
            </div>

            <el-input v-model="likeModel" placeholder="请输入备注进行模糊搜索" style="width: 200px;margin-left: 10px"
                      @input="likeInput"></el-input>

            <el-input v-model="likeBoxNumberModel" placeholder="请输入箱号进行模糊搜索" style="width: 250px;margin-left:10px"
                      @input="likeBoxNumberInput"></el-input>

            <el-input v-model="likeDeviceNameModel" placeholder="请输入设备名称进行模糊搜索" style="width: 250px;margin-left: 10px"
                      @input="likeDeviceNameInput"></el-input>

            <el-input v-model="likeSerialNumberModel" placeholder="请输入设备序列号进行模糊搜索" style="width: 250px;margin-left: 10px"
                      @input="likeSerialNumberInput"></el-input>

            <el-button size="mini" type="primary" style="margin-left: 20px;" @click="downloadOpenBoxRecord">导出</el-button>

            <el-button size="mini" type="primary" style="margin-left: 20px;" @click="refreshOpenBoxRecord">刷新</el-button>

        </div>
        <div class="door_open_box_record_data_content">
            <el-table ref="filterTable" :data="openBoxRecordData" v-loading="loading" height="100%" style="width: 100%">
                <el-table-column
                        label="序号"
                        align="center"
                        type="index"
                        width="100">
                </el-table-column>
                <el-table-column align="center" prop="user.userName" label="管理员" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="remark" label="备注" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="boxNumber" label="操作" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="deviceName" label="设备名称" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="serialNumber" label="设备序列号" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="date" label="日期" width="auto">
                </el-table-column>
            </el-table>
            <el-pagination style="margin-top: 20px;margin-bottom: 20px;" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="page"
                           :page-sizes="[20,40,80,160,320,640]"
                           :page-size="length" layout="total, sizes, prev, pager, next, jumper" :total="openBoxRecordCount">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                loading: false,
                page: 1,
                length: 20,
                datePickerValue: [localStorage.getItem('openBoxStartDate'), localStorage.getItem('openBoxStopDate')],
                startTime: 0,
                endTime: 0,
                likeModel: null,
                openBoxRecordData: [],
                openBoxRecordCount: 0,
                likeBoxNumberModel:null,
                likeDeviceNameModel:null,
                likeSerialNumberModel:null
            };
        },
        mounted() {
            this.refreshOpenBoxRecord()
        },
        methods: {
            datePickerChange(datePickerValue) {
                localStorage.setItem('openBoxStartDate', datePickerValue[0])
                localStorage.setItem('openBoxStopDate', datePickerValue[1])
                this.refreshOpenBoxRecord()
            },
            handleSizeChange(length) {
                this.length = length
            },
            handleCurrentChange(page) {
                this.page = page
                this.getOpenBoxRecordData()
            },
            handleClose(done) {
                done()
            },
            likeInput(value) {
                this.refreshOpenBoxRecord()
            },
            likeBoxNumberInput(value){
                this.refreshOpenBoxRecord()
            },
            likeDeviceNameInput(value){
                this.refreshOpenBoxRecord()
            },
            likeSerialNumberInput(value){
                this.refreshOpenBoxRecord()
            },
            getOpenBoxRecordData() {
                this.loading = true
                var startTime = new Date(this.datePickerValue[0]).getTime()
                var endTime = new Date(this.datePickerValue[1]).getTime()
                this.recordData = []
                setTimeout(() => {
                    axios.post('/doorOpenBoxRecord/pagingQuery', {
                        page: this.page,
                        length: this.length,
                        startTime: startTime,
                        endTime: endTime,
                        likeParameter: {
                            remark: this.likeModel,
                            boxNumber:this.likeBoxNumberModel,
                            deviceName:this.likeDeviceNameModel,
                            serialNumber:this.likeSerialNumberModel
                        }
                    }).then((res) => {
                        this.loading = false
                        if (res.data.code === 1) {
                            this.openBoxRecordData = res.data.data.content
                            this.openBoxRecordCount = res.data.data.totalElements
                        }else {
                            this.openBoxRecordData = []
                            this.openBoxRecordCount = 0
                        }
                    }).catch((error) => {
                        this.loading = false
                    })
                },500)
            },
            downloadOpenBoxRecord(){
                var startTime = new Date(this.datePickerValue[0]).getTime()
                var endTime = new Date(this.datePickerValue[1]).getTime()
                this.recordData = []
                this.loading = true
                setTimeout(() => {
                    axios.post('/doorOpenBoxRecord/download', {
                        page: this.page,
                        length: this.length,
                        startTime: startTime,
                        endTime: endTime,
                        likeParameter: {
                            remark: this.likeModel,
                            boxNumber:this.likeBoxNumberModel,
                            deviceName:this.likeDeviceNameModel,
                            serialNumber:this.likeSerialNumberModel
                        }
                    }, {
                        responseType: 'blob'
                    }).then((res) => {
                        this.loading = false
                        let data = res.data
                        const link = document.createElement('a')
                        let blob = new Blob([data], {
                            type: 'application/vnd.ms-excel'
                        })
                        link.style.display = 'none'
                        link.href = URL.createObjectURL(blob)
                        link.download = '开箱记录.xls'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    }).catch((error) => {
                        this.loading = false
                    })
                })
            },
            refreshOpenBoxRecord() {
                this.getOpenBoxRecordData()
            }
        }
    }
</script>

<style>
    .door_open_box_record_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .door_open_box_record_tabler {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        margin: 20px;
        min-height: 100px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .door_open_box_record_data_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        flex: 1;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        margin-bottom: 10px;
    }

</style>
